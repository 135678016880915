<template>
  <div class="container">
    <div class="logo"></div>
    <router-link
    v-on:click.native="handleJump"
    class="jumpItem addBorder" to="/user/">
      <img class="avatarIcon" src="@/assets/icon/Avatar.png" alt="" />
      <!-- {{ user.mist.firstname }} -->
      Dashboard
      <img class=" hidden sm:inline-block settingIcon" src="@/assets/icon/setting.png" alt="" />
    </router-link>
    <div>
      <div class="cc_menu hidden">
        <!-- <button @click="test">click</button> -->
        <!-- <div>{{ activeMenutab }}</div> -->
      <a-menu
        class=" border-black-75"
        style=" background: #163257;"
        :selectedKeys="defaultSelectedKeys"
        mode="inline"
        @click="handleMenuItemClick"
        :openKeys="defaultOpenKeys"
      >
        <a-sub-menu
        @titleClick="handleCCDash"
        key="CCtab" >
          <span slot="title"><span class=" select-none text-white text-base">账户余额</span></span>
            <a-menu-item  v-for="item in getSubMenuList" :key="item.path" class="py-2">
              <router-link
                v-on:click.native="()=> {handleJump(item.path)}"
                class=" text-white select-none  text-base "
                :to="item.path"
              >{{ item.title }}
                </router-link>
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </div>
      <div class="test">
        <router-link
            v-on:click.native="handleJump"
            class="jumpItem" to="/user/cc-account">账户余额</router-link>
        <router-link
            v-if="isCCactive"
            v-on:click.native="handleJump"
            class="jumpItem" to="/user/convert-list">货币兑换</router-link>
          <router-link
            v-if="isCCactive"
            v-on:click.native="handleJump"
            class="jumpItem" to="/user/beneficiary">收款人</router-link>
          <router-link
            v-if="isCCactive"
            v-on:click.native="handleJump"
            class="jumpItem" to="/user/payment-list">提现</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  created () {
  },
  data () {
    return {
      openKeys: [],
      collapsed: false,
      activeMenutab: '',
      cc_menu_tabs: [
        {
          key: 1,
          title: '汇兑',
          path_name: 'ConvertList',
          path: '/user/convert-list'
        },
        {
          key: 2,
          title: '收款人',
          path_name: 'BeneficiaryList',
          path: '/user/beneficiary'
        },
        {
          key: 3,
          title: '支付',
          path_name: 'CCPaymentList',
          path: '/user/payment-list'
        }
      ]
    }
  },
  computed: {
    ...mapState('login', ['user']),
    ...mapGetters('cc', ['isCCactive']),
    ...mapState(['isMobile']),
    getSubMenuList () {
      if (this.isCCactive) {
        return this.cc_menu_tabs
      } else {
        return []
      }
    },
    defaultSelectedKeys () {
      const beneficiaryList = ['BeneficiaryList', 'BeneficiaryAdd', 'BeneficiaryAddStepOne', 'BeneficiaryAddStepTwo', 'BeneficiaryAddStepThree', 'BeneficiaryAddStepFour']
      const paymentList = ['CCPaymentList', 'CCPaymentCreate']
      const convertList = ['ConvertList', 'Convert', 'ConvertConfirm']
      if (beneficiaryList.includes(this.$route.name)) {
        return ['/user/beneficiary']
      } else if (paymentList.includes(this.$route.name)) {
        return ['/user/payment-list']
      } else if (convertList.includes(this.$route.name)) {
        return ['/user/convert-list']
      } else return []
    },
    defaultOpenKeys () {
      const whiteList = ['CCAccount', 'BeneficiaryList', 'BeneficiaryAdd', 'BeneficiaryAddStepOne', 'BeneficiaryAddStepTwo', 'BeneficiaryAddStepThree', 'BeneficiaryAddStepFour', 'CCPaymentList', 'CCPaymentCreate', 'ConvertList', 'Convert', 'ConvertConfirm']
      return whiteList.includes(this.$route.name) ? ['CCtab'] : []
    }
    // activeMenutab () {
    //   return [this.$router.currentRoute.path]
    // }
  },
  methods: {
    test  () {
      console.log(this.openKeys)
    },
    handleJump (path) {
      this.activeMenutab = path
      if (this.isMobile) {
        this.$emit('leftMenuHide')
      }
    },
    handleMenuItemClick ({ item, key }) {
      const pathName = this.cc_menu_tabs.find(item => item.path === key).path_name
      if (this.$route.name !== pathName) {
        this.$router.push({ name: pathName })
      }
    },
    handleCCDash () {
      if (this.$route.name !== 'CCAccount') {
        this.$router.push({ name: 'CCAccount' })
      }
    }
  }
}
</script>

<style scoped>
.cc_menu >>> .ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
  left: 0 !important;
}
.cc_menu >>> .ant-menu-submenu > .ant-menu{
  background-color: transparent;
}

.cc_menu >>> .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background-color: rgba(0,0,0,0.4);;
}

.cc_menu >>> .ant-menu-item > a{
  color: white;
}
.cc_menu >>> .ant-menu-item-selected > a, .ant-menu-item-selected > a:hover{
  font-size: 1rem;
}
.container {
  flex-shrink: 0;
  width: 300px;
  box-sizing: border-box;
  min-height: 100vh;
  border: 1px solid black;
  background: #163257;
  z-index: 10;
}
.avatarIcon {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}
.settingIcon {
  width: 21px;
  height: 21px;
  right: 25px;
  top: 21px;
  position: absolute;
}
.logo {
  display: block;
  height: 80px;
  width: 100%;
  background: url("../../assets/img/white_long_logo.png") no-repeat center center / 70%;
}
.height80 {
  height: 80px;
}
.height60 {
  height: 60px;
}
.jumpItem {
  position: relative;
  display: block;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  padding-left: 24px;
  width: 100%;
  height: 60px;
  text-decoration: none;
  line-height: 48px;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
}
.paddingLeft22 {
  padding-left: 22px;
}
.paddingLeft29 {
  padding-left: 29px;
}
.test .router-link-exact-active {
  background: rgba(0, 0, 0, 0.4);
  color: #ffffff;
}
.test .router-link-exact-active::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0px;
  width: 4px;
  height: 100%;
  background: #bc9a41ff;
}
.addBorder {
  border-bottom: 1px solid black;
}
@media only screen and (max-width: 600px) {
.container {
  flex-shrink: 0;
  width: 45vw;
  box-sizing: border-box;
  min-height: 100vh;
  border: 0px solid black;
  background: #163257;
}
.avatarIcon {
  width: 8vw;
  height: 8vw;
  margin-right: 5vw;
}
.settingIcon {
  width: 21px;
  height: 21px;
  right: 12%;
  top: 21px;
  position: absolute;
}
.logo {
  display: block;
  height: 10vh;
  width: auto;
  background: url("../../assets/img/white_long_logo.png") no-repeat center center/34vw;
}
.height80 {
  height: 80px;
}
.height60 {
  height: 60px;
}
.jumpItem {
  position: relative;
  display: block;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  padding-left: 24px;
  width: 100%;
  height: 7vh;
  text-decoration: none;
  font-weight: 400;
  justify-content: flex-start;
  font-size: 16px;
  color: #ffffff;
}
.paddingLeft22 {
  padding-left: 0;
}
.paddingLeft29 {
  padding-left: 0;
}
.test{
  margin-top:  1vh;
}
.test .router-link-exact-active {
  background: rgba(0, 0, 0, 0.4);
  color: #ffffff;
}
.test .router-link-exact-active::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0px;
  width: 4px;
  height: 100%;
  background: #bc9a41ff;
}
.addBorder {
  border-bottom: 0px solid black;
}
}
</style>
